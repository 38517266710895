@import  '../../styles/CONF_COLORS';

nav {
  display: grid;
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  border-right: 1px solid $primary;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-row-gap: 0.3rem;
      align-items: center;
      justify-items: center;
      
      border-bottom: 1px solid $primary;
      cursor: pointer;
      transition: all 300ms ease-out;

      a {
        display: grid;
        align-items: center;
        justify-items: center;
        color: $primary;
        text-decoration: none;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        height: 100%;
      }

      svg {
        font-size: 4rem;
        color: $primary;
      }

      // .mnu-label {
      //   display: grid;
      //   color: $primary;
      //   text-align: center;
      // }

      &:hover {
        background-color: $primary-light;
      }

      &.selected {
        background-color: $fourth;
      }
    }
  }
}
@import  '../../styles/CONF_COLORS';
@import  '../../styles/MIX_LAYOUTS';
@import '../../styles/ASSET_FORM';

.main-wrapper {
  &.login {
    width: 100%;
    height: 100%;
    background-color: white;

    .form-wrapper {
      form {
        .msg {
          display: grid;
          align-items: center;
          justify-items: center;
          padding: 1rem;
          border: 1px solid $alert;
          color: $alert;
        }

        .line {
          grid-template-columns: 1fr;

          .message {
            font-weight: bold;
          }

          input[type=text], 
          input[type=email], 
          input[type=password] {
            width: 99%;
          }
        }
      }
    }
  }
}
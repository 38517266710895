/* ----- STYLES.CONF_COLORS ----- */

// Version : 17.08.2022

// ----- Corporate Colors -----
$primary                        : #384751; //Gris ardoise pour texte
$primary-medium									: #6187ab;
$primary-gradiant-1							: #829bbb;
$primary-gradiant								: #a5b7ce;
$primary-light									: #e4ebf1;
$hue                            : 208;
$sat                            : 56%;
$rot                            : 46%;
$black													: #333333; //gris très foncé pour les textes
$primary-hsl                    : hsl($hue, $sat, $rot);
$primary-dark                   : #2D436F; //bleu foncé UCO
$primary-blue-dark							: #0e4276; // bleu dark
$primary-blue-medium						: #1A5791; // bleu medium
$primary-blue-light							: #2E6CA8; // bleu plus sombre
$primary-gray-dark              : #A0A0A0; // gris pour les bordures
$primary-gray-medium            : #B0B0B0; // gris foncé pour des fonds
$primary-gray-light             : #E6E6E5; // gris clair pour des fonds
$primary-gray-ultra-light				: #f2f2f2; // gris très claire pour les interlignes
$second                         : #e50048; // #E6215C; //Rose foncé UCO
$third                          : #E50076; // 2eme rose foncé degradé des boutons
$fourth                         : #FDE8EE; // Rose très clair pour des fonds
$h1-color                       : $primary;
$h2-color                       : $primary;
$h3-color                       : $primary;
$h4-color                       : $primary;

$second-gray-medium             : #d1d1d1;

$second-gray-ultra-light        : #f7f7f7;

$field-obl											: #e52b38;
$field-obl-light								: #ff5d5d;

$label-check-radio							: #576b76;

$link-hover											: #154d84;

$notif-bg												: #FA4545;

$user-menu											: #384751;
$uco-resp-dark								  : #384751;
$second-dark										: #E6215C;

$menu-bg												:	#23325c;
$uco-resp-bleu								  :	#23325c;
$lip_cmagistral									:	#7893c6;
$lip_tlogiciel									: #8fc5bf;
$CLASS_HORAIRE_AUTRES						: #e39269; 


$current-day										: #d55770;
$gray-lighter										: #fbfbfb;

$highlight											: #D5072A;

// ----- Campus colors -----
$camp-angers: 		#7896C8; // lavande
$camp-guingamp:		#E22C69; // fushia
$camp-ifepsa:			#394752; // gris
$camp-reunion:		#E16143; // orange
$camp-laval:			#4D803F; // vert
$camp-nantes:			#4A9B92; // vert d'eau
$camp-niort:			#463091; // violet
$camp-papeete:		#F9A731; // jaune
$camp-vannes:			#237FC0; // bleu

// Couleurs générée avec https://color.adobe.com/fr/create

// ----- Semblable -----
$semblable-color-1              : spin($primary, 30);
$semblable-color-2              : spin($primary, 15);
$semblable-color-3              : spin($primary, -15);
$semblable-color-4              : spin($primary, -30);

// ----- Monochrome -----
$monochrome-color-1             : darken($primary, 15%);
$monochrome-color-2             : darken($primary, 8%);
$monochrome-color-3             : lighten($primary, 8%);
$monochrome-color-4             : lighten($primary, 15%);

// ----- Complémentaire -----
$complementaire-color-0         : spin($primary, -180);
$complementaire-color-1         : spin($monochrome-color-1, -180);
$complementaire-color-2         : spin($monochrome-color-2, -180);
$complementaire-color-3         : spin($monochrome-color-3, -180);
$complementaire-color-4         : spin($monochrome-color-4, -180);

// ----- Contrast -----
$contrast-color-1               : contrast($monochrome-color-1);
$contrast-color-2               : contrast($monochrome-color-2);
$contrast-color-3               : contrast($monochrome-color-3);
$contrast-color-4               : contrast($monochrome-color-4);

// ----- Transparency -----
$primary-transp-025             : hsla($hue, $sat, $rot, 0.25);
$primary-transp-05              : hsla($hue, $sat, $rot, 0.5);
$primary-transp-075             : hsla($hue, $sat, $rot, 0.75);

// ----- Gradiant -----
$primary-gradient-1             : darken($primary, 30%);
$primary-gradient-2             : darken($primary, 20%);
$primary-gradient-3             : darken($primary, 10%);
$primary-gradient-4             : $primary;
$primary-gradient-5             : lighten($primary, 10%);
$primary-gradient-6             : lighten($primary, 20%);
$primary-gradient-7             : lighten($primary, 30%);
$primary-gradient-8             : lighten($primary, 40%);

// ----- Info -----
$info                   : #008CBA;
$info-light             : lighten($info, 10%);  
$info-lighter           : lighten($info, 20%);  
$info-ultra-light       : lighten($info, 40%);  
$info-dark              : darken($info, 10%);   
$info-darker            : darken($info, 20%);   
$info-ultra-dark        : darken($info, 30%);   
$info-transp-025        : rgba(0, 102, 135, 0.25);
$info-transp-05         : rgba(0, 102, 135, 0.5);
$info-transp-075        : rgba(0, 102, 135, 0.75);

// ----- Success -----
$success                : #43AC6A;
$success-light          : lighten($success, 10%); 
$success-lighter        : lighten($success, 20%); 
$success-ultra-light    : lighten($success, 40%); 
$success-dark           : darken($success, 10%);  
$success-darker         : darken($success, 20%);  
$success-ultra-dark     : darken($success, 40%);  
$success-transp-025     : rgba(53, 135, 83, 0.25);
$success-transp-05      : rgba(53, 135, 83, 0.5);
$success-transp-075     : rgba(53, 135, 83, 0.75);

// ----- Warning -----
$warning                : #f08a24;
$warning-light          : lighten($warning, 10%); 
$warning-lighter        : lighten($warning, 20%); 
$warning-ultra-light    : lighten($warning, 40%); 
$warning-dark           : darken($warning, 10%);  
$warning-darker         : darken($warning, 20%);  
$warning-ultra-dark     : darken($warning, 40%);  
$warning-transp-025     : rgba(211, 113, 14, 0.25);
$warning-transp-05      : rgba(211, 113, 14, 0.5);
$warning-transp-075     : rgba(211, 113, 14, 0.75);

// ----- Alert -----
$alert                  : #f04124;
$alert-light            : lighten($alert, 10%); 
$alert-lighter          : lighten($alert, 20%); 
$alert-ultra-light      : lighten($alert, 40%); 
$alert-dark             : darken($alert, 10%);  
$alert-darker           : darken($alert, 20%);  
$alert-ultra-dark       : darken($alert, 40%);
$alert-transp-01        : rgba(211, 42, 14, 0.1);
$alert-transp-025       : rgba(211, 42, 14, 0.25);
$alert-transp-05        : rgba(211, 42, 14, 0.5);
$alert-transp-075       : rgba(211, 42, 14, 0.75);

// ----- Yellow -----
$yellow                  : #f0e224;
$yellow-light            : lighten($yellow, 10%); // #f36a54
$yellow-lighter          : lighten($yellow, 20%); // #f79483
$yellow-ultra-light      : lighten($yellow, 40%); // #fde7e3
$yellow-dark             : darken($yellow, 10%);  // #d32a0e
$yellow-darker           : darken($yellow, 20%);  // #a3210b
$yellow-ultra-dark       : darken($yellow, 40%);  // #430e05
$yellow-transp-025       : rgba(240, 226, 36, 0.25);
$yellow-transp-05        : rgba(240, 226, 36, 0.5);
$yellow-transp-075       : rgba(240, 226, 36, 0.75);
@import  '../../styles/CONF_COLORS';
@import  '../../styles/MIX_LAYOUTS';
@import '../../styles/ASSET_FORM';
@import '../../styles/ASSET_BODY';

.module-wrapper {
  .module-header {
    .module-title {}

    .modulte-tools {}
  }

  .module-body {
    .account-create {
      .title {
        font-weight: bold;
        margin-bottom: 2rem;
      }

      form {
        .form-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-auto-flow: row;
          grid-column-gap: 4rem;
          grid-row-gap: 0.5rem;
          align-items: start;

          .field-container {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            grid-row-gap: 0.3rem;
            margin-bottom: 1rem;

            &.no-label {
              label {
                display: none;
              }
            }

            &.span-2 {
              grid-column: 1 / span 2;
            }

            &.content-right {
              justify-items: end;
            }

            &.checkbox {
              grid-template-columns: 1.5rem auto;
              grid-template-rows: auto;
              grid-column-gap: 0.5rem;
              grid-row-gap: initial;
              align-items: center;
              margin-bottom: 0.3rem;

              label {
                font-size: 0.8rem;
              }
            }

            label {

            }

            .field {
              &.scrollable {
                max-height: 12rem;
                overflow-y: auto;
              }
            }
          }
        }
      }

      ul.account-fields-roles {
        margin: 0;
        padding: 0;
      }
    }

    .accounts-manager {
      .accounts-searchbar {
        margin-bottom: 1rem;

        input {
          width: 400px;
          height: 32px;
        }
      }

      ul.accounts-lst-wrapper {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li.accounts-lst-account {
          display: grid;
          grid-template-columns: 1fr 1fr 1.5fr 2fr 1fr 7rem 3rem 3rem 3rem;
          border-bottom: 1px solid $primary-gray-dark;
          transition: all 300ms ease-out;
          align-items: center;

          &.titles {
            background-color: $primary-gray-light;
            color: $primary;
            font-weight: bold;
          }

          &.client {
            background-color: $primary-gray-ultra-light;
          }

          div {
            padding: 0.4rem 0.5rem;

            &.account-email,
            &.account-organisation {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.account-roles {
              ul.account-lst-roles {
                margin: 0 0 0 1rem;
                padding: 0;

                li {
                  font-size: 0.6rem;
                }
              }
            }

            &.account-pwdchanged {
              &.green {
                color: $success;
              }

              &.red {
                color: $alert;
              }
            }

            &.btn {
              text-align: center;
              font-size: 1.2rem;
              opacity: 0.4;
              cursor: pointer;
              transition: all 300ms ease-out;

              &:hover {
                opacity: 1;
              }
            }
          }

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            background-color: $fourth;
          }
        }
      }
    }

    .account-import {
      .title {
        margin-bottom: 0.5rem;
      }

      form {
        margin-bottom: 1rem;

        .form-container {
          .field-container {
            display: grid;
            grid-template-columns: max-content max-content auto;
            grid-column-gap: 1rem;
            align-items: center;

            label {
              font-size: 0.8rem;
            }

            .field {

            }

            .button {
              justify-self: end;

              .tool-btn {
                @include toolbox-btn();
              }
            }
          }
        }
      }

      .accounts-imports {
        ul.accounts-imports-ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li.account-import-li {
            display: grid;
            grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr;
            border-bottom: 1px solid $primary-gray-dark;
            transition: all 300ms ease-out;
            align-items: center;

            &.title {
              background-color: $primary-gray-light;
              color: $primary;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
input {
  &[type="text"],
  &[type="email"],
  &[type="password"] {
    appearance: none;
    border: 1px solid $primary-gray-dark;
    width: 95%;
    height: 2rem;

    &:focus {
      border: 1px solid $primary-gray-dark;
    }
  }
}

button,
input[type="submit"] {
  appearance: none;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  background-color: $second;
  border: none;
  color: white;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  transition: all 300ms ease-out;
  font-size: 1rem;
  outline: none;


  &:hover {
    background-color: darken($second, 10%);
  }
}

select {
  // appearance: none;
  width: 97%;
  height: 36px;
  border: 1px solid $primary-gray-dark;
}
@import  '../../styles/CONF_COLORS';
@import  '../../styles/MIX_LAYOUTS';
@import '../../styles/ASSET_FORM';
@import '../../styles/ASSET_BODY';

.module-wrapper {
  .module-header {
    .module-title {}

    .modulte-tools {}
  }

  .module-body {
    .urls-manager {
      ul.urls-lst-wrapper {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;
        max-width: 100%;

        li.urls-lst-urls {
          display: grid;
          grid-template-columns: 1fr max-content;
          grid-template-rows: auto auto;
          border-bottom: 1px solid $primary-gray-dark;
          transition: all 300ms ease-out;
          align-items: center;

          &.titles {
            background-color: $primary-gray-light;
            color: $primary;
            font-weight: bold;
          }

          div {
            padding: 0.4rem 0.5rem;

            &.urls-url {
              grid-column: 1 / span 1;
              grid-row: 1 / span 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            &.urls-last-update {
              grid-column: 2 / span 1;
              grid-row: 1 / span 1;
            }

            &.urls-content-details {
              grid-column: 1 / span 2;
              grid-row: 2 / span 1;
            }
          }
        }
      }
    }
  }
}
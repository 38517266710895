@import  '../../styles/CONF_COLORS';
@import  '../../styles/MIX_LAYOUTS';
@import '../../styles/ASSET_FORM';

.main-wrapper {
  display:grid;
  align-items: center;
  justify-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ghostwhite;

  .form-wrapper {
    display: grid;
    border: 1px solid black;
    background-color: white;
    padding: 2rem;

    h1 {
      margin: 0;
      padding: 0;
      margin-bottom: 1rem;
    }

    .msg {
      padding: 1rem;
      margin-bottom: 1rem;
      display: grid;
      align-items: center;
      justify-items: center;

      &.error {
        border: 1px solid $second;
        color: $second;
      }

      &.success {
        border: 1px solid $success;
        color: $success;
      }
    }

    form {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      grid-row-gap: 1rem;

      .line {
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-auto-flow: row;
        grid-row-gap: 0.3rem;
        align-items: center;

        label {
          grid-column: 1 / span 2;
          grid-row: 1 / span 1;
        }

        input {
          grid-column: 1 / span 1;
          grid-row: 2 / span 1;

          &[type="text"],
          &[type="password"] {
            &.neutral {
              background-color: white;
              border: 1px solid $primary-gray-dark;
            }

            &.error {
              border: 1px solid $second;
              background-color: $fourth
            }
  
            &.success {
              border: 1px solid $success;
              background-color: $success-ultra-light;
            }
          }

          &[type="submit"] {
            &[disabled] {
              background-color: gray;
            }
          }
        }

        .icone {
          grid-column: 2 / span 1;
          grid-row: 2 / span 1;
          font-size: 1.5rem;
          color: $primary;
          opacity: 0.4;
          cursor: pointer;
          transition: all 300ms ease-out;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
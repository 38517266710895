@import  '../../styles/CONF_COLORS';

footer {
  display: grid;
  grid-column: 1 / span 2;
  grid-row: 3 / span 1;
  background-color: $primary;
  color: white;
  align-items: center;
  justify-items: center;
  font-size: 0.8rem;
}
@import  '../../styles/CONF_COLORS';

header {
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  align-items: center;
  background-color: $primary;
  position: sticky;
  padding: 0 1rem;

  .logo {}

  .main {
    display: grid;
    align-items: center;
    justify-items: center;

    h1 {
      color: white;
      font-size: 1.5rem;
    }
  }

  .tools {
    padding-right: 1rem;
    .btn {
      color: white;
      font-size: 2.5rem;
      opacity: 0.6;
      transition: all 300ms ease-out;

      &:hover {
        opacity: 1;
      }

      &.logout {}
    }
  }
}
@mixin toolbox-btn() {
  display: grid;
  grid-template-columns: 1.5rem auto;
  grid-column-gap: 0.5rem;
  align-items: center;
  background-color: $second;
  color: white;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  transition: all 300ms ease-out;
  font-size: 1.5rem;

  span {
    font-size: 1rem;
  }

  &:hover {
    background-color: darken($second, 10%);
  }
}
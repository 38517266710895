.module-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content auto;
  grid-row-gap: 1rem;
  padding: 0.3rem 0.5rem;

  .module-header {
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;

    .module-title {
      font-weight: bold;
    }

    .modulte-tools {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 0.5rem;
        width: max-content;

        li {
          .tool-btn {
            @include toolbox-btn();
          }
        }
      }
    }

    .module-body {
      grid-column: 1 / span 2;
    }
  }
}